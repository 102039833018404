body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.freedomBackground {
  background-image: url(./assets/freedom.jpg);
}

.mobileImage {
  height: 400px
}

.fwdSection {
  height: 850px;
}

.fwdSection2 {
  height: 550px;
}

.backgroundOff {
  background-color: #F4F4F4;
}

.topAndBottomPadding {
  padding: 16px 0px;
}

.cardWidth {
  flex: 0 0 100%;
  max-width: 100%;
}

.navImageLogo {
  height: 30px
}

#privacy p {
  font-size: 14px;
}

#privacy li {
  font-size: 14px;
}

#terms p {
  font-size: 14px;
}

#terms li {
  font-size: 14px;
}

.contactUs {
  text-align: center;
}

.fwdLogo {
  height: 130px;
  padding-bottom: 15px;
}

#footer a {
  color: #000;
  font-size: 14px;
}

#footer p {
  color: #000;
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .mobileImage {
    height: 600px
  }

  .fwdLogo {
    height: 150px;
  }

  .fwdSection {
    height: 800px;
  }

  .cardWidth {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .navImageLogo {
    height: 50px
  }
}

@media only screen and (min-width: 1024px) {
  .mobileImage {
    height: 600px
  }

  .fwdLogo {
    height: 150px;
  }
  
  .fwdSection {
    height: 800px;
  }

  .cardWidth {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .navImageLogo {
    height: 50px
  }
}

@media only screen and (min-width: 1440px) {
  .mobileImage {
    height: 700px
  }

  .fwdLogo {
    height: 150px;
  }

  .fwdSection {
    height: 800px;
  }

  .cardWidth {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .navImageLogo {
    height: 50px
  }
}